import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import storage from "../../../Storage";
import { encode } from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

const Logout = () => {
    const navigate = useNavigate();

    const ask = () => {
        Swal.fire({
            title: 'Are you sure to logout?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                socket.emit(C.LOGOUT_USER, encode({ token: storage.getKey('token') }));
                localStorage.clear();
                Cookies.remove("session");
                Cookies.remove("auth");
                Cookies.remove("uid");
                navigate('/');
            }
        });
    };

    return (
        <button onClick={ask} className="dropdown-item">
            <i className="dripicons-exit text-muted ml-1 mr-2 text-drop"></i>
            Logout
        </button>
    );
};

export default Logout;
