import React from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default class Social extends React.Component {
    render() {
        const { t } = this.props;
        return ( <
            >
            <
            span className = "btn-social-icon" >
            <
            ReactTooltip / >
            <
            a href = { t('facebook') }
            target = { '_blank' }
            dataTip = { 'FaceBook Page' } rel="noreferrer" >
            <
            i className = { 'mdi mdi-facebook-box font-25' }
            style = {
                { color: 'rgb(93 120 127)' }
            }
            /> < /
            a > <
            a href = { t('twitter') }
            target = { '_blank' }
            dataTip = { 'Twitter News' } rel="noreferrer" >
            <
            i className = { 'mdi mdi-twitter-box font-25' }
            style = {
                { color: 'rgb(93 120 127)' }
            }
            /> < /
            a > <
            a href = { t('discord') }
            target = { '_blank' }
            dataTip = { 'Discord Page' } rel="noreferrer" >
            <
            i className = { 'mdi mdi-discord font-25' }
            style = {
                { color: 'rgb(93 120 127)' }
            }
            /> < /
            a > <
            a href = { t('dribble') }
            target = { '_blank' }
            dataTip = { 'Follow us on Dribble' } rel="noreferrer" >
            <
            i className = { 'mdi mdi-dribbble-box font-25' }
            style = {
                { color: 'rgb(93 120 127)' }
            }
            /> < /
            a > <
            a href = { t('medium') }
            target = { '_blank' }
            dataTip = { 'Medium' } rel="noreferrer" >
            <
            i className = { 'mdi mdi-quality-medium font-25' }
            style = {
                { color: 'rgb(93 120 127)' }
            }
            /> < /
            a > <
            /span> < /
            >
        );
    }
}