import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import socket from '../../../../Socket';
import { decode, Event } from '../../../../Helper';
import Details from './Details';
import TipUser from '../Tip/TipUser';

const UserModal = ({ coin = 'btc' }) => {
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState(true);
  const [tip, setTip] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [data, setData] = useState([]);
  const [effect, setEffect] = useState('fadeIn');

  useEffect(() => {
    socket.on('USER_INFO', (data) => getUserInfo(decode(data)));

    Event.on('force_modal_user', () => {
      setShow(true);
      setEffect('zoomIn');
      setData([]);
    });

    Event.on('force_modal_tip', () => {
      setTip(true);
      setDetail(false);
    });

    Event.on('force_modal_tip_close', () => {
      setTip(false);
      setDetail(true);
    });

    return () => {
      socket.off('USER_INFO');
      Event.off('force_modal_user');
      Event.off('force_modal_tip');
      Event.off('force_modal_tip_close');
    };
  }, []);

  const getUserInfo = (data) => {
    if (data.status) {
      setData(data);
    } else {
      setNotFound(true);
    }
  };

  const handleClose = () => {
    setShow(false);
    setEffect('zoomOut');
  };

  return (
    <Modal show={show} onHide={handleClose} size='md' centered className={`animated ${effect}`}>
      <Modal.Header closeButton>
        <Modal.Title>User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notFound ? (
          <div className='alert alert-danger' role='alert'>
            User Not Found
          </div>
        ) : (
          <>
            {detail && <Details data={data} onHide={handleClose} />}
            {tip && <TipUser data={data} onHide={handleClose} />}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

UserModal.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps)(UserModal);
