const coins = [
    {
        id: 1,
        name: "BTC",
        preffix: "btc",
        image: "btc.png",
        network: 'btc',
        min: "0.00008000",
        active: true
    },
];

export default coins;