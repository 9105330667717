import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import socket from "../../../../Socket";
import { decode, encode, __, timeConvertor, forceSatoshiFormat } from "../../../../Helper";
import C from "../../../../Constant";

const Details = ({ haveData, token, name, coin, data, t }) => {
  const [details, setDetails] = useState({
    games: 0,
    wins: 0,
    losses: 0,
    profit: 0,
    wagered: 0,
    timestamp: 0,
  });

  useEffect(() => {
    socket.on(C.USER_STATS, (response) => {
      const decodedData = decode(response);
      setDetails(decodedData);
    });

    if (haveData === "ok") {
      socket.emit(C.USER_STATS, encode({ token: token, name: name, coin: coin }));
    }

    return () => {
      socket.off(C.USER_STATS);
    };
  }, [haveData, token, name, coin]);

  return (
    <Card className="details-card text-white">
      <Card.Header className="border-bottom">
        <h4 className="card-title">{t('user_statistics')}</h4>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={6}>
            <div className="stat-box">
              <span className="stat-title">{t('games_played')}</span>
              <span className="stat-value">{details.games}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stat-box">
              <span className="stat-title">{t('wins')}</span>
              <span className="stat-value">{details.wins}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stat-box">
              <span className="stat-title">{t('losses')}</span>
              <span className="stat-value">{details.losses}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stat-box">
              <span className="stat-title">{t('profit')}</span>
              <span className="stat-value">{forceSatoshiFormat(details.profit)}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stat-box">
              <span className="stat-title">{t('wagered')}</span>
              <span className="stat-value">{forceSatoshiFormat(details.wagered)}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stat-box">
              <span className="stat-title">{t('last_played')}</span>
              <span className="stat-value">{timeConvertor(details.timestamp)}</span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Details;
