import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import socket from '../../../../Socket';
import { __, decode, Event, fixDate, forceSatoshiFormat } from '../../../../Helper';

const SingleGameModal = ({ coin = 'btc' }) => {
  const [show, setShow] = useState(false);
  const [effect, setEffect] = useState('fadeIn');
  const [details, setDetails] = useState({});

  useEffect(() => {
    Event.on('single_game_modal', (result) => {
      setDetails(result.data);
      setShow(true);
      setEffect('pulse');
    });

    Event.on('single_game_modal_close', () => {
      handleClose();
    });

    return () => {
      Event.off('single_game_modal');
      Event.off('single_game_modal_close');
    };
  }, []);

  const handleClose = () => {
    setShow(false);
    setEffect('fadeOut');
  };

  const { name, amount, game, profit, created, hash, gid, result, slot } = details;
  const date = fixDate(created);
  const displayAmount = forceSatoshiFormat(amount);
  const displayProfit = forceSatoshiFormat(profit);

  return (
    <Modal show={show} onHide={handleClose} size='lg' centered className={`animated ${effect}`}>
      <Modal.Header closeButton>
        <Modal.Title>Game Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Body>
                <h5>Bet ID: {gid}</h5>
                <p>Date: {date}</p>
                <p>Game: {game}</p>
                <p>Amount: {displayAmount} {coin.toUpperCase()}</p>
                <p>Profit: {displayProfit} {coin.toUpperCase()}</p>
                <p>Hash: {hash}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <h5>Result: {result}</h5>
                <p>Player: {name}</p>
                <p>Slot: {slot}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

SingleGameModal.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps)(SingleGameModal);
