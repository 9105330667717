import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Index from "./App/Pages";
import NotFound from "./App/Pages/404";
import Support from "./App/Pages/Support";
import Affiliate from "./App/Pages/Affiliate";
import Bonus from "./App/Pages/Affiliate/Bonus";

import UserStat from "./App/Components/User/Stat";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";
import Leaderboard from "./App/Pages/Leaderboard";

import Crash from "./App/Games/Crash";

function Router(props) {
  let location = useLocation();
  let background = location.state && location.state.background;

  const { t } = props;

  return (
    <>
      <Routes location={background || location}>
        <Route
          path="/"
          element={<Index t={t} location={location} content={<Crash t={t} />} />}
        />
        <Route
          path="/leaderboard"
          element={<Index t={t} content={<Leaderboard t={t} />} />}
        />
        <Route
          path="/support"
          element={<Index t={t} content={<Support t={t} />} />}
        />
        <Route
          path="/affiliate"
          element={<Index t={t} content={<Affiliate t={t} history={props.history} />} />}
        />
        <Route
          path="/aff"
          element={<Index t={t} content={<Bonus params={location} history={props.history} t={t} />} />}
        />
        <Route
          path="/setting"
          element={<Index t={t} content={<UserSetting t={t} />} />}
        />
        <Route
          path="/user/:id"
          element={<Index t={t} content={<UserStat t={t} params={location} />} />}
        />
        <Route
          path="/game/:id"
          element={<Index t={t} content={<GameStat t={t} params={location} />} />}
        />
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </>
  );
}

export default Router;
