import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import { __, defaultAvatar, encode, Event } from "../../../../Helper";

class UserLink extends Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
    isWinner: PropTypes.bool,
    cssClass: PropTypes.string,
    queue: PropTypes.bool,
    menu: PropTypes.bool,
    avatar: PropTypes.string,
    chat: PropTypes.bool,
    level: PropTypes.number,
    coin: PropTypes.string
  };

  static defaultProps = {
    isWinner: false,
    cssClass: '',
    queue: false,
    menu: false,
    avatar: defaultAvatar,
    chat: false,
    level: 0,
    coin: 'btc' 
  };

  constructor(props) {
    super(props);
    this.state = {
      coin: props.coin
    };
  }

  getDetail = (e) => {
    e.preventDefault();
    if (this.props.clicked) {
      this.props.clicked(); 
    }
    Event.emit('single_game_modal_close');
    Event.emit('force_modal_user');
    socket.emit(C.USER_INFO, encode({ name: this.props.username, coin: this.state.coin, game: 'all', first: true }));
  };

  makeStars = (num) => {
    const stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<i key={`star-success-${i}`} className={'mdi mdi-star text-success'} />);
    }
    for (let i = 0; i < 5 - num; i++) {
      stars.push(<i key={`star-default-${i}`} className={'mdi mdi-star'} />);
    }
    return <div className="stars-container">{stars}</div>;
  }

  renderLink = () => {
    const { username, cssClass, menu, queue, avatar, chat, level } = this.props;
    const userDetailsLink = `/user/${username}`;
    let linkContent;

    if (menu) {
      linkContent = (
        <p className="p-0 m-0">
          <img src={avatar || defaultAvatar} alt="profile-user" className="thumb-md rounded-circle" />
        </p>
      );
    } else if (queue) {
      linkContent = (
        <>
          <img src={avatar || defaultAvatar} alt="user" className="rounded-circle thumb-xs mr-1 hidden-sm" />
          {username}
        </>
      );
    } else if (chat) {
      linkContent = (
        <>
          <img src={avatar || defaultAvatar} alt="user" className="thumb-lg" />
          <ul className={'ml-2 mt-0 d-flex'}>
            <span>{username}</span>
            <b className="user-level">{this.makeStars(level)}</b>
          </ul>
        </>
      );
    } else {
      linkContent = username;
    }

    return (
      <Link to={userDetailsLink} onClick={this.getDetail} className={this.props.isWinner ? 'text-success' : cssClass}>
        {linkContent}
      </Link>
    );
  }

  render() {
    return this.renderLink();
  }
}

const mapStateToProps = state => ({
  coin: state.items.coin
});

export default connect(mapStateToProps)(UserLink);
