import React from 'react';
import { Link } from 'react-router-dom';
import socket from '../../../../Socket';
import { Event, encode } from '../../../../Helper';
import C from '../../../../Constant';

const GameModal = ({ game_id, title, font = 14, clicked }) => {
  const getDetail = (e, id) => {
    e.preventDefault();
    if (clicked !== undefined) clicked();
    Event.emit('force_modal_game');
    socket.emit(C.GAME_DETAILS, encode({ id }));
  };

  return (
    <Link to={`/game/${game_id}`} onClick={(e) => getDetail(e, game_id)} className={`text-white font-${font}`}>
      {title}
    </Link>
  );
};

export default GameModal;
