import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import storage from "../../../Storage";
import GoogleBtn from "./Google";
import Forget from "./Forget";
import socket from "../../../Socket";
import { Event, wait, decode, encode, randomString, RECAPTCHA } from "../../../Helper";
import C from "../../../Constant";
import { Row, Col } from 'react-bootstrap';

const Login = ({ justModal, t }) => {
    const [state, setState] = useState({
        show: justModal || false,
        forgetPage: false,
        username: "",
        password: "",
        recaptcha: false,
        status: false,
        submitted: false,
        disabled: false,
        effect: "zoomIn",
    });
    const recaptchaRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleLoginUser = (data) => {
            const decodedData = decode(data);
            setLogin(decodedData);
        };

        socket.on(C.LOGIN_USER, handleLoginUser);
        return () => {
            socket.off(C.LOGIN_USER, handleLoginUser);
        };
    }, []);

    const setLogin = (data) => {
        if (data.status === true) {
            setState(prevState => ({
                ...prevState,
                status: "Successfully Login, Please Wait...",
                show: false,
                effect: "zoomOut"
            }));
            Cookies.set("session", data.token, { expires: 14 });
            storage.setAllKeys(data);

            socket.emit(C.ONLINE, encode({
                jwt_token: storage.getKey("jwt_token"),
                user_token: storage.getKey("user_token"),
                security_key: storage.getKey("security_key"),
                secret_user: storage.getKey("secret_user"),
                secret_realtime: storage.getKey("secret_realtime"),
                client_system: storage.getKey("client_system"),
                token_key: storage.getKey("token_key"),
                secret_token: storage.getKey("secret_token"),
                token: data.token,
            }));

            navigate("/");
            wait(1000).then(() => window.location.reload());
        } else {
            setState(prevState => ({ ...prevState, status: data.status, submitted: false, disabled: false }));
        }
    };

    const handleShow = () => setState(prevState => ({ ...prevState, show: true, effect: "zoomIn" }));
    const handleClose = () => {
        setState(prevState => ({
            ...prevState,
            show: false,
            effect: "zoomOut",
            disabled: false,
            status: false,
            submitted: false,
        }));
        Event.emit("showAuthModal", false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, password } = state;
        if (!username || !password) {
            setState(prevState => ({ ...prevState, disabled: false, status: false }));
            return;
        }

        setState(prevState => ({ ...prevState, submitted: true, disabled: true, status: t("please_wait") }));
        wait(1000).then(() => {
            socket.emit(C.LOGIN_USER, encode({
                username: username,
                password: password,
                recaptcha: "google",
            }));
        });
    };

    const forgetPass = () => setState(prevState => ({ ...prevState, forgetPage: !prevState.forgetPage }));
    const recaptchaChange = (value) => setState(prevState => ({ ...prevState, recaptcha: value }));

    return (
        <>
            {!justModal && (
                <button className="btn btn-transparent w-100 text-left py-0" onClick={handleShow}>
                    <i className="dripicons-user text-muted mr-2"></i> {t("login")}
                </button>
            )}
            <Modal
                size="md"
                centered
                backdrop="static"
                show={state.show}
                onHide={handleClose}
                aria-labelledby="login-md-modal"
                className={"modalAuth animated " + state.effect}
            >
                <Modal.Header>
                    {t("login")}
                    <button type="button" className="close p-2" onClick={handleClose}>
                        <i className={"mdi mdi-close"} />
                    </button>
                </Modal.Header>
                <Modal.Body className="auth-modal p-0">
                    <div className="m-auto">
                        {!state.forgetPage && (
                            <div>
                                <div className="px-3">
                                    <div className="text-center">
                                        {state.recaptcha}
                                        <img src="/assets/images/logo.png" className="img-fluid auth-logo" alt="" />
                                        <div className="text-center auth-logo-text">
                                            <p className="mt-0 mb-3 mt-3 font-new text-white">
                                                {t("mega_profit")}
                                                <i className="mdi mdi-dots-horizontal mx-2 font-18 align-middle" />
                                                {t("fair_games")}
                                            </p>
                                        </div>
                                    </div>
                                    <form className="form-horizontal auth-form my-4" onSubmit={handleSubmit}>
                                        <div className="form-group mb-2">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text bgp">{t("username")}</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={state.username}
                                                    autoComplete="off"
                                                    onChange={(e) => setState(prevState => ({ ...prevState, username: e.target.value }))}
                                                    style={{ height: 40 }}
                                                />
                                                {state.submitted && !state.username && (
                                                    <div className="help-block">{t("username_is_required")}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group mb-2">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text bgp">{t("password")}</span>
                                                </div>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={state.password}
                                                    autoComplete="off"
                                                    onChange={(e) => setState(prevState => ({ ...prevState, password: e.target.value }))}
                                                    style={{ height: 40 }}
                                                />
                                                {state.submitted && !state.password && (
                                                    <div className="help-block">{t("password_is_required")}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-secondary btn-block font-weight-bold no-shadow" disabled={state.disabled}>
                                                <i className="mdi mdi-login mr-1 float-left font-18" /> {t("login_to_site")}
                                            </button>
                                        </div>
                                        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA} />
                                    </form>
                                    <Row className="text-center mb-4">
                                        <Col md="6" className="my-1">
                                            <GoogleBtn />
                                        </Col>
                                        <Col md="6" className="my-1">
                                            <a href="#" onClick={forgetPass} className="btn btn-block btn-info shadow-none">
                                                <i className="mdi mdi-information mr-1" /> {t("rest_password")}
                                            </a>
                                        </Col>
                                    </Row>
                                    {state.status && (
                                        <div className={"alert bg-secondary text-white"}>{state.status}</div>
                                    )}
                                </div>
                            </div>
                        )}
                        {state.forgetPage && <Forget t={t} clicked={forgetPass} />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Login;