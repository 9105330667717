import React, { Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import i18next from "i18next";
import { I18nextProvider, useTranslation } from "react-i18next";
import history from "./history";
import Route from "./Router";
import storage from "./Storage";
import { chkd, wait, DEVELOPMENT } from "./Helper";
import UserModal from "./App/Components/User/Stat/UserModal";
import GameModal from "./App/Components/Game/Stat/GameModal";
import SingleGameModal from "./App/Components/Game/Stat/SingleGameModal";
import ErrorBoundary from "./App/Components/ErrorBoundary";

// Debugging logs
console.log("UserModal:", UserModal);
console.log("GameModal:", GameModal);
console.log("SingleGameModal:", SingleGameModal);
console.log("ErrorBoundary:", ErrorBoundary);

i18next.init({
  interpolation: { escapeValue: false },
});

const delay = DEVELOPMENT ? 300 : 4300;

const Loader = () => <></>;

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Page />
      </ErrorBoundary>
    </Suspense>
  );
}

function Page() {
  const { t } = useTranslation();
  return <Application t={t} />;
}

const Application = (props) => {
  const { t } = props;
  const [state, setState] = React.useState({
    loading: true,
    welcome: !!storage.getKey("welcome"),
    effect: "d-none",
  });

  React.useEffect(() => {
    security();

    wait(700).then(() => {
      setState((prevState) => ({ ...prevState, effect: "zoomIn" }));
    });

    wait(delay).then(() => {
      setState((prevState) => ({ ...prevState, loading: false }));
    });
  }, []);

  const security = () => {
    console.log(
      'Stop! This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a feature or do a "hack", it is a scam and will give them access to your account',
      "color:#FFF; background:red; font-family:system-ui; font-size:20px; font-weight:bold"
    );

    chkd();
  };

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        {state.loading && (
          <div className="loading">
            <div className="loading-text">
              <img
                className={`img-fluid animated ${state.effect}`}
                src="/assets/images/logo.png"
                alt="Loading"
              />
              <br />
              <span className="loading-text-words">L</span>
              <span className="loading-text-words">O</span>
              <span className="loading-text-words">A</span>
              <span className="loading-text-words">D</span>
              <span className="loading-text-words">I</span>
              <span className="loading-text-words">N</span>
              <span className="loading-text-words">G</span>
            </div>
          </div>
        )}
        {!state.loading && (
          <div>
            <ReactNotifications />

            <UserModal t={t} />
            <GameModal t={t} />
            <SingleGameModal t={t} props={props} />
            {/* <h1>Hello</h1> */}
          </div>
        )}
        <Route t={t} history={history} />
      </I18nextProvider>
    </BrowserRouter>
  );
};

Application.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  chart_coin: state.items.chart_coin,
});

export default connect(mapStateToProps, {})(App);
